<template>
  <div class="container">
    <div id="auth-container" class="row">
      <div v-if="isPasswordChanged" class="col-12 p-0">
        <div class="row">
          <div class="col-sm-4 offset-sm-4">
            <div class="alert alert-success">
              <b>Password successfully changed!</b> You may now
              <router-link to="/login">log in</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="col-12 p-0">
        <div class="row">
          <div class="col-sm-4 offset-sm-4">
            <div class="alert alert-danger"><b>Error!</b> {{ error }}</div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 offset-sm-4">
        <div class="alert alert-info">
          <h1 class="text-center mb-3">Change Password</h1>
          <form @submit.prevent="changePassword">
            <div class="form-group">
              <input
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Password"
                required
                @focus="isPasswordFocused = true"
                @blur="isPasswordFocused = false"
              />
            </div>
            <div class="form-group">
              <input
                v-model="confirm_password"
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                required
                @focus="isPasswordFocused = true"
                @blur="isPasswordFocused = false"
              />
              <span
                class="text-danger"
                v-if="
                  !isPasswordFocused &&
                    password &&
                    confirm_password &&
                    password !== confirm_password
                "
                >Passwords must match</span
              >
            </div>
            <button
              type="submit"
              class="btn btn-block btn-primary"
              :disabled="isSubmitting"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      password: '',
      confirm_password: '',
      uid: null,
      token: null,
      isSubmitting: false,
      isPasswordChanged: false,
      isPasswordFocused: false
    }
  },
  computed: {
    ...mapState('auth', {
      error: state => state.error
    })
  },

  mounted() {
    this.uid = this.$route.params.uid
    this.token = this.$route.params.token
  },

  methods: {
    changePassword() {
      if (this.password !== this.confirm_password) {
        return
      }

      let _this = this

      this.isSubmitting = true
      this.$store
        .dispatch('auth/changePassword', {
          uid: this.uid,
          token: this.token,
          password: this.password
        })
        .then(data => {
          _this.$store.dispatch('auth/clearError')
          _this.isPasswordChanged = true
        })
        .finally(() => (_this.isSubmitting = false))
    }
  }
}
</script>

<style scoped>
#auth-container {
  margin-top: 50px;
}

.tab-content {
  padding-top: 20px;
}
</style>
