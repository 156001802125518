<template>
  <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1">
    <!-- <button @click="acceptInvite" class="btn btn-primary" :disabled="isSubmitting">Accept Invite</button> -->

    <div class="d-flex align-self-center mx-auto flex-grow-1">
      <div class="d-flex flex-grow-1 align-self-center mx-auto bg-white p-5 rounded" style="">
        <div class="row mt-5">
          <div class="col-md-6 col-sm-12">
            <h1>Welcome to Refera<br /><small>Let's get you setup</small></h1>
            <p class="lead">Please complete the information below.</p>
          </div>
          <div class="col text-center text-info" v-if="isProvisioning">
            <font-awesome-icon icon="spinner-third" size="6x" spin />
            <br />
            Creating Your Account...
          </div>
          <div class="col-md-6 col-sm-12" v-else>
            <form @submit.prevent="setup">
              <div class="form-group row">
                <div class="col-12 col-sm-6">
                  <input
                    v-model="user.first_name"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="First Name"
                    required
                    v-validate
                    name="first_name"
                  />
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <input
                    v-model="user.last_name"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Last Name"
                    required
                    v-validate
                    name="last_name"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group input-group-lg">
                  <input
                    v-model="user.new_password"
                    :type="isPwVisible ? 'text' : 'password'"
                    class="form-control"
                    placeholder="Password"
                    required
                    v-validate
                    name="new_password"
                    autocomplete="new-password"
                  />
                  <div class="input-group-append">
                    <a
                      href="javascript:void(0)"
                      class="btn"
                      :class="[isPwVisible ? 'btn-primary' : 'btn-outline-primary']"
                      @click.prevent="isPwVisible = !isPwVisible"
                      tabindex="-1"
                    >
                      <font-awesome-icon :icon="['far', isPwVisible ? 'eye-slash' : 'eye']" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group text-right">
                <accept-tocs />
              </div>
              <button type="submit" class="btn btn-block btn-primary" :disabled="isProvisioning">
                {{ isProvisioning ? 'Creating...' : 'Next' }}
              </button>
            </form>
          </div>
          <div v-if="error" class="col">
            <div class="col alert alert-danger text-center">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import AcceptTocs from '@/components/shared/AcceptTocs'

export default {
  components: { AcceptTocs },
  data() {
    return {
      error: '',
      isProvisioning: false,
      isPwVisible: false,
      user: {
        first_name: '',
        last_name: '',
        new_password: '',
      },
    }
  },

  methods: {
    setup() {
      const _this = this
      this.isProvisioning = true
      AuthService.provision({
        user: this.user,
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      })
        .then((data) => {
          _this.$store.dispatch('auth/loginViaToken', { token: data.token }).then(() => _this.$router.push(`/`))
        })
        .catch(_this.$helpers.handleApiError(_this))
        .finally(() => (_this.isProvisioning = false))
    },
  },
}
</script>
