<template>
  <div class="error-wrapper">
    <div class="wrapper">
      <h1>Page not found</h1>
      <p>The page cannot be found</p>
      <div class="buttons">
        <router-link to="/" class="btn btn-lg btn-primary">Back to dashboard</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
}
</script>
