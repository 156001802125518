<template>
  <div class="d-flex align-self-center mx-auto flex-grow-1 justify-content-between">
    <div class="d-flex flex-grow-1 align-self-center control-container">
      <div class="d-flex flex-column flex-grow-1">
        <!-- <img
          src="@/assets/svg/logo.svg"
          style="width: 100px"
          class="d-none d-md-inline-block mx-auto main-logo mb-5"
        /> -->
        <div v-if="isPasswordEmailSent" class="d-flex flex-column flex-grow-1">
          <div class="alert alert-success">
            <b>Please check your email!</b>
            A password reset email has been sent to {{ email }}
          </div>
        </div>
        <div v-if="isTokenLogin" class>
          <h4 v-if="!error" class="text-center mb-3 text-hint font-weight-bold">
            <font-awesome-icon icon="spinner-third" spin size="2x" class="mb-3 text-primary" /><br />Getting things
            ready...
          </h4>
          <div v-else class="mb-3 text-right">
            <div class="alert alert-danger text-danger text-left">
              {{ error }}
            </div>
            <a href="#" @click.prevent="backToLogin" class="btn btn-outline-primary mt-1">back to login</a>
          </div>
        </div>
        <div v-else-if="!isForgotPassword" class>
          <div class v-if="isEmailTokenScreen">
            <!-- <CountdownCircle /> -->

            <h3 class="text-left" :class="error ? 'mb-3' : 'mb-3'">Check your email</h3>

            <div v-if="tokenRedirectMessage" class="">
              <p class="text-danger font-weight-bold">
                {{ tokenRedirectMessage }}
              </p>
            </div>
            <p v-else>Please enter a 6-digit code that we sent to your email.</p>
            <form @submit.prevent="signin">
              <div class="form-group">
                <CodeInput
                  v-model="token"
                  :loading="isLoggingIn"
                  class="input"
                  :invalid="!!error"
                  :class="{ 'is-invalid': error }"
                  required
                  @complete="signin"
                />

                <!-- <input v-model="token" type="text" class="form-control form-control-lg form-control-xl" placeholder required :class="{ 'is-invalid': error }" /> -->
              </div>
              <div v-if="error" class="">
                <div class="text-danger font-weight-bold">
                  {{ error }}
                </div>
              </div>
              <button type="submit" class="btn btn-block btn-lg btn-primary" :disabled="isLoggingIn">
                {{ isLoggingIn ? 'Signing in...' : 'Sign in' }}
              </button>
              <div class="pt-3 text-center">
                <a href="javascript:void(0)" @click="authEmail">Resend code to {{ email }}</a>
              </div>
            </form>
          </div>
          <div class v-else-if="isOneTimeLogin">
            <h1 v-if="showWelcomeText" class="text-left" :class="error ? 'mb-3' : 'mb-4'">Welcome to Refera!</h1>
            <!-- <h3 class="text-left" :class="error ? 'mb-3': 'mb-3'">Enter your email</h3>
            <p>We'll send you a one time code to login.</p> -->
            <template v-if="availableLogins.length && isAvailableLoginsVisible">
              <login-list @newAccount="isAvailableLoginsVisible = false" @selected="loginExisting" />
              <div class="mt-3 flex-1 d-flex">
                <router-link to="/send" class="d-flex align-items-center px-3 py-2 ml-auto font-weight-bold">
                  <span style="font-size: 0.85rem">Send a referral as guest</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" class="ml-2" style=""
                /></router-link></div
            ></template>
            <form v-else @submit.prevent="authEmail">
              <div class="form-group">
                <label>Email</label>
                <input
                  v-model="email"
                  type="text"
                  class="form-control form-control-lg form-control-xl"
                  placeholder
                  required
                  :class="{ 'is-invalid': error }"
                />
              </div>
              <div v-if="error" class="">
                <div class="text-danger font-weight-bold">
                  {{ error }}
                </div>
              </div>
              <button type="submit" class="btn btn-block btn-lg btn-primary" :disabled="isLoggingIn">
                {{ isLoggingIn ? 'Processing...' : 'Next' }}
              </button>
              <div class="pt-3 text-center">
                <a href="javascript:void(0)" @click="toggleOneTimeLogin(false)">Take me to password login</a>
                <!-- <router-link to="/register">No Account? Click here to sign up</router-link> -->
              </div>
              <div v-if="availableLogins.length" class="pt-3">
                <a @click="isAvailableLoginsVisible = true" href="javascript:void(0)"
                  ><font-awesome-icon :icon="['fas', 'chevron-left']" class="mr-1" /> Back to users</a
                >
              </div>
            </form>
          </div>
          <div class v-else>
            <h1 class="text-left" :class="error ? 'mb-3' : 'mb-4'">Welcome to Refera!</h1>
            <form @submit.prevent="signinWithPassword">
              <div class="form-group">
                <label>Email</label>
                <input
                  v-model="email"
                  type="text"
                  class="form-control form-control-lg form-control-xl"
                  placeholder
                  required
                  :class="{ 'is-invalid': error }"
                />
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control form-control-lg form-control-xl"
                  placeholder
                  required
                  :class="{ 'is-invalid': error }"
                />
              </div>
              <div v-if="error" class="">
                <div class="text-danger font-weight-bold">
                  {{ error }}
                </div>
              </div>
              <button type="submit" class="btn btn-block btn-lg btn-primary mt-3" :disabled="isLoggingIn">
                {{ isLoggingIn ? 'Processing...' : 'Login with password' }}
              </button>
            </form>
            <h6 class="text-center w-100 my-4 decorated text-muted font-weight-normal"><span>or</span></h6>
            <button
              @click="toggleOneTimeLogin(true)"
              class="btn btn-block btn-lg btn-outline-primary mt-2"
              :disabled="isLoggingIn"
            >
              One time login code
            </button>
            <!-- <a href="javascript:void(0)" class="font-weight-bold">No password? Get a one-time login code</a> -->
            <!-- <div class="pt-3 text-center">
              <a href="javascript:void(0)" @click="isPasswordScreen=false">Go back</a>
            </div> -->
          </div>
        </div>
        <div v-else class>
          <!-- todo: this is an ugly way to change the max-width of 345px -->
          <h2 class="text-left" :class="error ? 'mb-3' : 'mb-4'">Reset your password</h2>
          <div v-if="error" class="mb-3">
            <div class="text-danger">
              Error!
              {{ error }}
            </div>
          </div>
          <p class="mb-4">
            Type in your email address below and we’ll send you an email with instructions on how to create a new
            password.
          </p>
          <form @submit.prevent="forgotPassword">
            <div class="form-group">
              <label>Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control form-control-lg form-control-xl"
                placeholder="example@email.com"
                required
              />
            </div>
            <button type="submit" class="btn btn-block btn-primary btn-lg" :disabled="isSubmittingForgotPwRequest">
              Reset password
            </button>
          </form>
          <div class="pt-3">
            <a href="javascript:void(0)" @click="isForgotPassword = false">
              <font-awesome-icon :icon="['far', 'angle-left']" /> Back to sign in
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="d-flex align-items-center switch-user-panel mr-5 p-5 bg-white rounded"
      v-if="!(isPasswordEmailSent || isTokenLogin || isForgotPassword || isEmailTokenScreen)"
    >
      <div class="">
        <h3>Welcome back</h3>
        <login-list />
      </div>
    </div> -->
  </div>
</template>

<script>
const DEFAULTS = {
  email: '',
  password: '',
  token: '',
  isLoggingIn: false,
  isForgotPassword: false,
  isSubmittingForgotPwRequest: false,
  isPasswordEmailSent: false,
  isTokenLogin: false,
  isPasswordScreen: false,
  isOneTimeLogin: true,
  isAvailableLoginsVisible: true,
}
import { mapGetters, mapState } from 'vuex'
import CodeInput from '@/components/auth/CodeInput'
import CountdownCircle from '../../components/_layout/CountdownCircle.vue'
import LoginList from '../../components/_layout/LoginList.vue'
export default {
  props: {
    showWelcomeText: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: String,
      default: null,
    },
  },
  components: {
    CodeInput,
    CountdownCircle,
    LoginList,
  },
  data() {
    return {
      ...DEFAULTS,
    }
  },
  computed: {
    ...mapState('auth', {
      error: (state) => state.error,
    }),
    ...mapGetters('auth', ['isLoggedIn', 'availableLogins']),
    isEmailTokenScreen() {
      return this.$route.name == 'LoginVerifyEmailToken'
    },
    isOtaAutoLogin() {
      return this.$route.name?.startsWith('AutoLoginWithOtaCode')
    },
    from() {
      return this.redirect || (this.$route.query.from && atob(this.$route.query.from))
    },
    fromToken() {
      //gets ?t= token in from url
      //eslint-disable-next-line
      return /\/[^\/]+\/\d+\/\?t\=(.*)/gi.exec(this.from?.split('&')?.[0])?.[1]
    },
    fromWithoutToken() {
      return this.fromToken ? this.from.replace('t=' + this.fromToken, '') : this.from
    },
    tokenRedirectMessage() {
      return this.$route.query.m && atob(this.$route.query.m)
    },
  },

  async mounted() {
    this.$store.dispatch('auth/clearError')

    if (!this.isOtaAutoLogin && (this.fromToken || this.$route.params.token)) {
      this.isTokenLogin = true
      const tokens = this.fromToken ? [this.fromToken] : this.$route.params.token.split(',')

      try {
        await Promise.all(tokens.map((token) => this.$store.dispatch('auth/loginViaToken', { token })))
        this.$router.push(this.fromWithoutToken || '/')
      } catch {
        this.isTokenLogin = false
        //todo: /auth-v2/token should issue a /auth-v2/email which will send a code, then just show OneTimelogin here instead.
        this.$helpers.error('Link expired, please login')
        try {
          this.$track('$expiredLink')
        } catch {}
        // this.$store.commit('auth/setError', 'Link expired, please login')
        this.$router.push({
          path: '/login',
          query: { from: btoa(this.fromWithoutToken) },
        })
      }
    }
  },

  async beforeRouteEnter(to, from, next) {
    if (to.name == 'LoginVerifyEmailToken') {
      try {
        let email = atob(to.params?.email)
        next((vm) => (vm.email = email))
      } catch (e) {
        this.$exception(e)
        next('login')
      }
    } else if (to.name?.startsWith('AutoLoginWithOtaCode')) {
      try {
        let email = atob(to.params?.email)
        let token = atob(to.params?.token)
        next((vm) => {
          vm.email = email
          vm.token = token
          vm.isTokenLogin = true
          vm.signin()
        })
      } catch (e) {
        this.$exception(e)
        next('login')
      }
    }
    next()
  },

  methods: {
    reset() {
      this.$store.dispatch('auth/clearError')
      Object.keys(DEFAULTS).forEach((k) => (this[k] = DEFAULTS[k]))
    },
    backToLogin() {
      this.toggleOneTimeLogin(true)
      this.isTokenLogin = false
      this.$router.replace('/login')
    },
    toggleOneTimeLogin(isVisible) {
      this.$store.dispatch('auth/clearError')
      this.isOneTimeLogin = isVisible
    },
    async authEmail() {
      this.$store.dispatch('auth/clearError')

      this.isLoggingIn = true
      try {
        const data = await this.$store.dispatch('auth/authEmail', {
          email: this.email,
        })
        this.$router.push(`/login/verify/${btoa(this.email)}`)
      } catch (e) {
        this.$exception(e)
      }

      this.isLoggingIn = false
    },
    async signinWithPassword() {
      this.$store.dispatch('auth/clearError')

      this.isLoggingIn = true
      try {
        const data = await this.$store.dispatch('auth/loginWithPassword', {
          email: this.email,
          password: this.password,
        })
        if (data) this.$router.replace(this.from || '/')
      } catch (e) {}

      this.isLoggingIn = false
    },
    async signin() {
      this.$store.dispatch('auth/clearError')

      this.isLoggingIn = true
      try {
        const data = await this.$store.dispatch('auth/login', {
          email: this.email,
          token: this.token,
        })

        this.$router.replace(this.from || '/', () => (this.isLoggingIn = false))
      } catch (e) {
        this.isLoggingIn = false
        if (this.isOtaAutoLogin) {
          this.$router.replace({
            name: 'LoginVerifyEmailToken',
            params: { email: this.$route.params.email },
          })
        }
      }
    },

    forgotPassword() {
      let _this = this

      this.isSubmittingForgotPwRequest = true
      this.$store
        .dispatch('auth/forgotPassword', { email: this.email })
        .then((data) => {
          _this.isPasswordEmailSent = true
          _this.$store.dispatch('auth/clearError')
        })
        .catch((response) => {
          _this.isPasswordEmailSent = false
        })
        .finally(() => (_this.isSubmittingForgotPwRequest = false))
    },
    onTokenComplete(v) {},

    async loginExisting(e) {
      try {
        await this.$store.dispatch('auth/switchUser', e)
        await this.$store.dispatch('auth/me', e)
        this.$router.push('/')
      } catch (err) {
        if (err == 'Unauthorized') {
          this.email = e.email
          this.authEmail()
        } else {
          console.error(err)
          this.$helpers.error('Failed to authenticate')
        }
      }
    },
  },
}
</script>
<style scoped>
.form-group {
  margin-bottom: 1rem;
}
label {
  line-height: 1.5rem;
}
h1,
h2 {
  font-size: 2.75rem;
  letter-spacing: -2px;
}
h2 {
  font-size: 2.625rem;
}
h3 {
  font-size: 2.5rem;
}
.btn {
  padding: 0.75rem;
  margin-top: 2.25rem;
}
.control-container {
  padding: 0 1rem;
}
@media (min-width: 576px) {
  h1,
  h2 {
    font-size: 3.75rem;
    letter-spacing: -2px;
  }
  .control-container {
    max-width: 345px;
    margin-left: 10%;
    padding: 0;
  }
}
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
}
.decorated > span:before,
.decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid var(--border-color);
  width: 100vw;
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}
.switch-user-panel {
  border-radius: 8px;
  background: #ffffffb7;
  padding: 2rem;
}
</style>
