<template>
  <div class="container d-flex align-items-center justify-content-center">
    <div
      v-if="!isSubmitting && isLoggedIn"
      class="d-flex align-self-center mx-auto flex-grow-1 p-5 box-shadow"
      style="background: #fff; max-width: 450px"
    >
      <div class="d-flex flex-grow-1 align-self-center mx-auto" style="max-width: 320px">
        <div class="d-flex flex-column flex-grow-1">
          <h2>
            You're already logged in
            <br />
            <small>Log out to continue registration</small>
          </h2>
          <div class="pt-3 d-flex" style="justify-content: space-between">
            <a
              href="javascript:void(0)"
              @click.prevent="$store.dispatch('auth/clientLogoutAll')"
              class="btn btn-lg btn-info"
              >LOG OUT</a
            ><br />
            <router-link to="/" class="btn btn-lg btn-link">Back to Dashboard</router-link>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div
        class="progress-panel"
        :class="{ submitting: isSubmitting }"
        :style="{
          background: `linear-gradient(to bottom, white 99%, transparent 99%), linear-gradient(to right, var(--primary-color) ${percentage}%, #EFF3F3 ${percentage}%)`,
        }"
      >
        <form @submit.prevent="submit">
          <section class="step" :class="[validatedStep >= 1 ? 'was-validated' : '']" v-if="isSubmitting">
            <transition name="fade" mode="out-in">
              <div class="text-center text-muted">
                <font-awesome-icon size="3x" icon="spinner-third" spin class="text-primary" />
              </div>
            </transition>
          </section>
          <template v-else>
            <div class="d-flex justify-content-between mb-5 align-items-center">
              <a
                href="javascript:void(0)"
                class="d-flex align-items-center text-muted"
                :style="{ visibility: step > 1 ? 'visible' : 'hidden' }"
                @click="previousStep()"
              >
                <font-awesome-icon :icon="['far', 'angle-left']" class="mr-2" />
                Back
              </a>
              <span class="text-primary font-weight-bold">Step {{ displayStep }}/{{ totalSteps }}</span>
            </div>
            <div class="signup-container">
              <transition name="slide-fade" mode="out-in" :duration="300">
                <section
                  class="step"
                  :class="[validatedStep >= 1 ? 'was-validated' : '']"
                  v-if="step == 1"
                  key="step-1"
                >
                  <div class="form-group">
                    <h3 class="mb-3">What’s the <b>name</b> of your office?</h3>
                    <div class>
                      <input
                        v-model="practice.name"
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Refera Dental Practice"
                        required
                        autocomplete="organization"
                        autofocus
                        ref="practiceNameEl"
                      />
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary" @click="nextStep()">Next </a>
                  </div>
                </section>
                <section
                  class="step"
                  :class="[validatedStep >= 2 ? 'was-validated' : '']"
                  v-else-if="step == 2"
                  key="step-2"
                >
                  <div class="form-group">
                    <h3 class="mb-3">What’s the <b>email</b> for the office?</h3>
                    <div class>
                      <input
                        v-model="practice.email"
                        type="email"
                        class="form-control form-control-lg"
                        placeholder="frontdesk@referadental.com"
                        required
                        autocomplete="email"
                        autofocus
                        ref="emailEl"
                      />
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary" @click="nextStep()">Next </a>
                  </div>
                </section>
                <section class="step" :class="[validatedStep >= 3 ? 'was-validated' : '']" v-else-if="step == 3">
                  <div class="form-group">
                    <h3 class="mb-3">What’s the <b>phone number</b> of your office?</h3>
                    <div class>
                      <cleave
                        type="tel"
                        v-model="practice.phone"
                        :options="cleave.phone"
                        class="form-control form-control-lg"
                        placeholder="855 473 3372"
                        required
                        autocomplete="tel"
                        autofocus
                        pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                        ref="phoneEl"
                        @input="validatedStep >= 3 && validateEl('phoneEl')"
                      ></cleave>
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary" @click="nextStep()">Next </a>
                  </div>
                </section>
                <section class="step" :class="[validatedStep >= 4 ? 'was-validated' : '']" v-else-if="step == 4">
                  <div class="form-group">
                    <h3 class="mb-3">What’s the <b>address</b> of your office?</h3>
                    <div class="autocomplete-lg autocomplete-xl">
                      <autocomplete
                        ref="addressEl"
                        @selected="addressSelected"
                        :value="
                          (practice.zip &&
                            [practice.address, practice.address_2, practice.city, practice.state]
                              .filter((s) => s)
                              .join(', ')) ||
                          ''
                        "
                      />
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary" @click="nextStep()">Next </a>
                  </div>
                </section>
                <section class="step" :class="[validatedStep >= 5 ? 'was-validated' : '']" v-else-if="step == 5">
                  <div class="form-group">
                    <h3 class="mb-3">Is {{ practice.name }} a <b>General Dentist</b> or <b>Specialist</b>?</h3>
                    <div class="row">
                      <div class="col">
                        <a
                          href="javascript:void(0)"
                          @click="
                            practice.specialty = 0
                            step += 2
                          "
                          class="btn btn-block text-nowrap btn-xl"
                          :class="[practice.specialty == 0 ? 'btn-primary' : 'btn-outline-primary']"
                          >General Dentist</a
                        >
                        <a
                          href="javascript:void(0)"
                          @click="
                            practice.specialty = -1
                            step++
                          "
                          class="btn btn-block btn-xl"
                          style="padding: 0.75rem 0.25rem"
                          :class="[practice.specialty == -1 ? 'btn-primary' : 'btn-outline-primary']"
                          >Specialist</a
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="button-container mt-auto">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-block btn-lg btn-primary"
                    :disabled="practice.specialty === null"
                    :class="[practice.specialty === null ? 'disabled' : '']"
                    @click="step += practice.specialty === -1 ? 1 : 2"
                    >Next
                  </a>
                </div> -->
                </section>
                <section class="step" :class="[validatedStep >= 6 ? 'was-validated' : '']" v-else-if="step == 6">
                  <div class="form-group">
                    <h3 class="mb-3">Great! Which <b>specialty</b>?</h3>
                    <div
                      class="specialty-select"
                      style="margin: 0 auto; display: grid; grid-template-columns: 1fr 1fr; gap: 10px 10px"
                    >
                      <div class="" v-for="(s, i) in PracticeTypes.filter((el, k) => k > 0)" :key="s">
                        <a
                          href="javascript:void(0)"
                          @click="
                            practice.specialty = i + 1
                            nextStep()
                          "
                          class="btn btn-block text-nowrap"
                          :class="[practice.specialty == i + 1 ? 'btn-primary' : 'btn-outline-primary']"
                          >{{ s }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="button-container mt-auto">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-block btn-lg btn-primary"
                    @click="nextStep()"
                    :disabled="practice.specialty === null"
                    :class="[practice.specialty === null ? 'disabled' : '']"
                    >Next
                  </a>
                </div> -->
                </section>
                <section class="step" :class="[validatedStep >= 7 ? 'was-validated' : '']" v-if="step == 7">
                  <div class="form-group">
                    <h3 class="mb-3">
                      What’s the name of the <b>owner doctor</b>?
                      <small class="text-muted d-block mt-1" style="font-size: 1rem"
                        >Just one if there are multiple</small
                      >
                    </h3>
                    <div>
                      <input
                        v-model="practice.owner_doctor_first"
                        type="text"
                        class="form-control form-control-lg mb-3"
                        placeholder="Owner First"
                        required
                        ref="ownerFirstEl"
                      />
                    </div>
                    <div>
                      <input
                        v-model="practice.owner_doctor_last"
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Owner Last"
                        required
                        ref="ownerLastEl"
                      />
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <a href="javascript:void(0)" class="btn btn-block btn-lg btn-primary" @click="nextStep()">Next </a>
                  </div>
                </section>
                <section class="step" :class="[validatedStep >= 8 ? 'was-validated' : '']" v-if="step == 8">
                  <div class="form-group">
                    <h3 class="mb-3">Choose a <b>password</b>...</h3>
                    <password-field v-model="password" autofocus />
                  </div>
                  <div class="form-group text-right row no-gutters">
                    <div class="col text-left">
                      <accept-tocs name="FINISH" class="text-body" />
                    </div>
                  </div>
                  <div class="button-container mt-auto">
                    <button type="submit" class="btn btn-block btn-lg btn-primary" :disabled="isSubmitting">
                      <font-awesome-icon v-if="isSubmitting" icon="spinner-third" spin class="mr-1" />
                      {{ isSubmitting ? 'Processing...' : 'Finish' }}
                    </button>
                    <!-- <a
                    href="javascript:void(0)"
                    class="btn btn-block btn-lg btn-primary"
                    @click="submit()"
                    :disabled="!user.password.isValid"
                    :class="[!user.password.isValid ? 'disabled' : '']"
                    >Finish
                  </a> -->
                  </div>
                  <img
                    height="1"
                    width="1"
                    style="display: none"
                    alt=""
                    src="https://px.ads.linkedin.com/collect/?pid=2779556&conversionId=9006380&fmt=gif"
                  />
                </section>
              </transition>
            </div>
          </template>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import { PracticeApi, InvitationsApi } from '@/services'
import usStates from '@/constants/us-states'
import AcceptTocs from '@/components/shared/AcceptTocs'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import Autocomplete from '@/components/shared/address-lookup/Autocomplete'
import PasswordField from '@/components/shared/PasswordFieldBootstrap'

export default {
  components: {
    AcceptTocs,
    Autocomplete,
    Cleave,
    PasswordField,
  },
  data() {
    return {
      isLight: false,
      step: 1,
      validatedStep: 0,
      practice: {
        name: '',
        website: '',
        phone: '',
        email: '',
        address: '',
        address_2: '',
        city: '',
        state: null,
        zip: '',
        npi: 'MANUAL_ADD',
        specialty: null,
        owner_doctor_first: '',
        owner_doctor_last: '',
      },
      isFormVisible: true,
      isSubmitting: false,
      usStates,

      isSaving: false,
      isPwVisible: true,
      password: null,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        is_doctor: null,
        practice: null,
      },
      errorHandler: null,
      userRole: null,
      cleave: {
        phone: {
          phoneRegionCode: 'us',
          phone: true,
          // blocks: [0, 3, 3, 4],
          // delimiters: ['(', ') ', '-', ' '], //backspace not working
          // numericOnly: true,
        },
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    // if (to.params) {
    //   const inviteCode = to.params.inviteCode
    //   if (inviteCode) {
    //     InvitationsApi.get(inviteCode, { anonymous: true })
    //       .then(
    //         (invite) => next((vm) => vm.initInvite({ practice: invite.practice }))
    //         //PracticeApi.get(invite.practice.id, { anonymous: true }).then(practice => next(vm => vm.initInvite({ practice })))
    //       )
    //       .catch((err) => {
    //         next((vm) => {
    //           vm.$helpers.error('Invalid Invite Code')
    //         })
    //       })
    //   }
    // }
    if (to.query) {
      const { name, email } = to.query
      if (name || email) {
        let fName = ''
        let lName = ''
        let isLikelyOwnerDoctor = ''
        if (name) {
          const sanatized = name.replace(/dr\.?\s?/gi, '') || ''
          isLikelyOwnerDoctor = sanatized.length < name.length
          const names = sanatized.split(' ')
          fName = names[0]
          lName = names.filter((s, i) => i > 0).join(' ')
        }
        return next((vm) => {
          vm.user.first_name = fName
          vm.user.last_name = lName
          if (email) {
            vm.user.email = email
            vm.practice.email = email
          }
          if (isLikelyOwnerDoctor) {
            vm.practice.owner_doctor_first = fName
            vm.practice.owner_doctor_last = lName
            vm.userRole = 'owner'
          }
        })
      }
    }
    return next()
  },
  computed: {
    ...mapState('auth', {
      error: (state) => state.error,
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapGetters('settings', ['PracticeTypes']),
    displayStep() {
      if (this.step >= 6 && this.practice && this.practice.specialty === 0) return this.step - 1
      return this.step
    },
    totalSteps() {
      return this.practice.specialty ? 8 : 7
    },
    percentage() {
      return this.step * (100 / this.totalSteps)
    },
  },

  mounted() {
    this.errorHandler = this.$helpers.handleApiError(this)
    this.$store.dispatch('auth/clearError')
  },

  methods: {
    initInvite({ practice }) {
      this.practice = practice
      this.isFormVisible = true
    },
    //submit() {
    //  const _this = this;
    //  this.isSubmitting = true;
    //  this.$store.dispatch('practices/create', this.practice)
    //    .then(data => _this.$router.push(`/register/${data.id}/admin`))
    //    .catch(_this.$helpers.handleApiError(_this))
    //    .finally(() => _this.isSubmitting = false)
    //},
    onWebsiteChange() {
      const website = this.practice.website
      if (!website || website.match('^https?://')) return
      this.practice.website = 'http://' + this.practice.website
    },
    addressSelected: function (address) {
      this.practice.address = address.address
      this.practice.address_2 = address.address_2
      this.practice.city = address.city
      this.practice.state = address.state
      this.practice.zip = address.zip
      this.practice.location = address.location
    },
    manualAdd() {
      this.practice.npi = 'MANUAL_ADD'
      this.isNpiNumberVisible = false
      this.isOfficeEditVisible = true
      this.isFormVisible = true
    },
    validateEl(name, errMsg) {
      let el = this.$refs[name]
      if (!el) return true
      el = el.$el || el
      el.setCustomValidity('')
      const isValid = el.checkValidity()
      if (errMsg && !isValid) el.setCustomValidity(errMsg)
      el.reportValidity()
      return isValid
    },
    validateStep() {
      this.validatedStep = this.step
      if (this.step >= 8) {
        if (!this.password.isValid) return false
      }
      if (this.step >= 7) {
        let isFnameValid = this.validateEl('ownerFirstEl')
        let isLnameValid = this.validateEl('ownerLastEl')
        if (!isFnameValid || !isLnameValid) return
      }
      if (this.step >= 6) {
        if (this.practice.specialty === -1) return
      }
      if (this.step >= 5) {
        if (this.practice.specialty === null) return
      }
      if (this.step >= 4) {
        if (!this.practice.zip) return
      }
      if (this.step >= 3) {
        if (!this.validateEl('phoneEl', 'Please enter a valid US phone number')) return
      }
      if (this.step >= 2) {
        if (!this.validateEl('emailEl')) return
      }
      if (this.step >= 1) {
        if (!this.validateEl('practiceNameEl')) return
      }
      return true
    },

    previousStep() {
      if (this.step === 7 && this.practice.specialty === 0) this.step--
      this.step--
      if (this.$posthog)
        this.$posthog.capture('$signupPrevStep', {
          step: this.step,
          practice: this.practice,
        })
    },

    nextStep() {
      if (this.validateStep()) this.step++
      if (this.$posthog)
        this.$posthog.capture('$signupNextStep', {
          step: this.step,
          practice: this.practice,
        })
    },

    async submit() {
      if (this.step < 8) {
        if (this.validateStep()) this.step++
        return
      }
      const dispatch = this.$store.dispatch
      this.errors.clear()
      this.isSubmitting = true
      try {
        this.practice.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        this.practice = await dispatch('practices/create', this.practice)
        this.user.practice = this.practice.id
        this.user.email = this.practice.email
        this.user.first_name = this.practice.owner_doctor_first
        this.user.last_name = this.practice.owner_doctor_last
        this.user.is_doctor = true
        this.user.password = this.password?.value
        //todo: a single endpoint needed to create both practice and user
        await dispatch('auth/signup', this.user)
        this.conversion()
        this.$helpers.success("Welcome to Refera - we're delighted to get you started!")
        await dispatch('auth/loginWithPassword', {
          email: this.user.email,
          password: this.user.password,
        })
        await this.$router.replace({ path: '/' })
        return
      } catch (err) {
        this.isSubmitting = false
        this.errorHandler(err)
        this.trackException('Sign up error', false)
        this.$exception(err)
      }
      this.isSubmitting = false
    },
    userRoleChange() {
      this.user.first_name = this.practice.owner_doctor_first
      this.user.last_name = this.practice.owner_doctor_last
    },
    conversion() {
      // this.linkedInImg = '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2779556&conversionId=9006380&fmt=gif" />'
      this.$track('$SignUp', {
        method: this.practice.specialty,
      })
      // try {
      //   this.$gtag.event('sign_up', {
      //     method: this.practice.specialty,
      //   })
      // } catch (err) {}
      // try {
      //   this.$gtag.event('conversion', {
      //     send_to: 'AW-424270545/dwgaH7KkocA6EJHh7MAC',
      //   })
      // } catch (err) {}
    },
    trackException(description, fatal) {
      this.$track('$SignUpException', {
        description,
        fatal,
      })
      // try {
      //   this.$gtag.event('exception', {
      //     description,
      //     fatal,
      //   })
      // } catch (err) {}
    },
    trackTimedEvent(name, value) {
      // try {
      //   this.$gtag.event('timing_complete', {
      //     name,
      //     value,
      //   })
      // } catch (err) {}
    },
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.25, 0.4, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.progress-panel {
  background-color: #ffffff;
  padding: 0.75rem 2rem;
  box-shadow: 0px 12px 25px rgba(43, 55, 51, 0.08);
  border-radius: 5px;
  min-height: 425px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  &.submitting {
    align-items: center;
  }
  .signup-container {
    max-width: 350px;
    margin: 0 auto;
    .step {
      h3 {
        font-size: 1.75rem;
      }
      .form-control {
        padding: 0.5rem 1.25rem;
        height: calc(1.5em + 1.25rem + 2px);
      }
      .btn {
        padding: 0.75rem 0.25rem;
      }
    }
    .button-container {
      margin-top: 1rem;
      // margin-bottom: 4.625rem;
      .btn {
        padding: 0.75rem;
      }
    }
  }
}
@media (min-width: 576px) {
  .progress-panel {
    padding: 2.25rem 4.625rem;
    min-width: 500px;
  }
}
</style>
