<template>
  <div class="d-flex flex-column align-items-center justify-content-center flex-grow-1">
    <!-- <button @click="acceptInvite" class="btn btn-primary" :disabled="isSubmitting">Accept Invite</button> -->

    <div v-if="!isSubmitted && !isSubmitting && isLoggedIn" class="d-flex align-self-center mx-auto flex-grow-1">
      <div class="d-flex flex-grow-1 align-self-center mx-auto" style="max-width: 320px">
        <div class="d-flex flex-column flex-grow-1">
          <img src="@/assets/svg/logo.svg" style="width: 100px" class="d-none d-md-inline-block mx-auto main-logo mb-5" />
          <template v-if="!this.$route.params.referral">
            <h2>
              You're already logged in
              <br />
              <small>Log out to accept invite</small>
            </h2>
            <p>
              If you received an invite and you're already on Refera; the office
              who invited you may have used a different email. That's fine,
              <a :href="`mailto:support@refera.io?subject=${encodeURIComponent(
                  'My practice is active on Refera but I have received an invite'
                )}&body=${encodeURIComponent(emailBody)}`">just let us know</a>
              and we can rectify the connection.
            </p>
            <div class="pt-3 d-flex" style="justify-content: space-between">
              <a href="javascript:void(0)" @click.prevent="$store.dispatch('auth/logout')" class="btn btn-lg btn-info">Log Me Out</a>
              <router-link to="/" class="btn btn-lg btn-primary">Back to Dashboard</router-link>
            </div>
          </template>
          <div v-else class="text-center text-muted">
            <font-awesome-icon icon="spinner-third" spin size="3x" class="text-primary" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSubmitting && !error" class="text-center">
      <font-awesome-icon icon="spinner-third" spin size="3x" class="text-primary" />
    </div>
    <div class="alert alert-danger text-danger text-center" v-if="error">
      {{ error }}
      <router-link to="/login" v-if="error.includes('please login')" class="d-block mx-auto">Login Here</router-link>
      <router-link to="/register" v-if="error == 'Invite not found'">Register Here</router-link>
    </div>
  </div>
  <!-- <router-view class="onboarding-container"></router-view> -->
</template>
<style>
::-webkit-input-placeholder {
  font-weight: normal;
}

:-ms-input-placeholder {
  font-weight: normal;
}

::placeholder {
  font-weight: normal;
}
</style>
<script>
import AuthService from '@/services/AuthService'
import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return { isSubmitting: false, isSubmitted: false }
  },
  computed: {
    ...mapState('auth', ['error', 'user']),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    emailBody() {
      return `Please rectify this invite as I would like to accept it under my practice ${this.user.practice.name}.\r\n\r\n[PLEASE KEEP THE LINE BELOW IN THE EMAIL REQUEST]\r\n${this.$route.params.inviteKey}|${this.user.practice.id}`
    },
  },
  async mounted() {
    if (!this.isLoggedIn) this.acceptInvite()
    else {
      if (this.$route.params.opreport) {
        await this.$router.replace(
          '/view/op-report/' + this.$route.params.opreport
        )
        return
      }
      if (this.$route.params.referral) {
        await this.$router.replace('/referral/' + this.$route.params.referral)
      }
    }
  },
  watch: {
    isLoggedIn(newVal, oldVal) {
      if (oldVal && !newVal) this.acceptInvite()
    },
  },
  methods: {
    async acceptInvite() {
      this.$store.dispatch('auth/clearError')
      this.isSubmitting = true
      await this.$store.dispatch('auth/inviteLogin', {
        inviteKey: this.$route.params.inviteKey,
        referral: this.$route.params.referral,
        opreport: this.$route.params.opreport,
      })
      this.isSubmitted = true
      this.isSubmitting = false
    },
  },
}
</script>
