<template>
  <div class="card border-0">
    <div class="card-header">
      <card-header
        title="Practice Management Software Integration"
        :hideBack="true"
      />
    </div>
    <div class="card-body">
      <div class="row" v-if="isComplete">
        <div class="col alert alert-success">All done!</div>
      </div>
      <template v-else>
        <div v-if="error" class="row alert alert-danger">
          <div class="col">
            <span class="text-center text-danger">{{ error }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Integrate via Sikka</p>
            <p class="lead">Click below to start integration with Sikka</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col">
            <div class="text-center">
              <a v-if="link" :href="link" target="blank">{{ link }}</a>
              <button
                class="btn btn-lg btn-primary"
                @click="integrate"
                :disabled="isLoading"
              >
                Connect with Sikka
              </button>
              {{ status }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { IntegrationApi, PatientApi } from '@/services'

export default {
  props: ['isComplete'],
  data() {
    return {
      isLoading: false,
      status: '',
      error: '',
      link: ''
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    isPracticeIntegrated() {
      return (
        this.user &&
        this.user.practice &&
        this.user.practice.is_integration_active
      )
    }
  },
  async mounted() {},
  async created() {},
  watch: {
    isPracticeComplete(val) {
      if (!val) return
      this.$router.push('/?tour=true')
      this.isCompletingSetup = false
    }
  },
  methods: {
    async integrate() {
      this.isLoading = true
      try {
        this.status = 'Connecting to Sikka'

        let response = await IntegrationApi.get('sikka-auth')
        this.status = 'Redirecting to Sikka'
        // this.link = response.location;
        setTimeout(() => (window.location = response.location), 1000)
      } catch (err) {
        this.error = (err && err.message) || 'Failed to integrate'
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped></style>
